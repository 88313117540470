// @flow strict
import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import { useSiteMetadata } from '../hooks';
import Gallery from '@browniebroke/gatsby-image-gallery'
import '@browniebroke/gatsby-image-gallery/dist/style.css'
import Sidebar from '../components/Sidebar';
import Page from '../components/Page';

type Props = {};

const GalleryTemplate = ({ data }: Props) => {
  const { title: siteTitle, subtitle: siteSubtitle } = useSiteMetadata();
  const images = data.allImageSharp.edges.map(({ node }) => node)
  // `images` is an array of objects with `thumb` and `full`
  return (
    <Layout title={`גלריית הקישוטים - ${siteTitle}`} description={'גלריית הקישוטים שלי'}>
      <Sidebar />
      <Page title="גלריית הקישוטים שלי">
        <Gallery images={images} />
      </Page>
    </Layout>
  );
};

export const query = graphql`
  query ImagesForGallery {
  allImageSharp {
    edges {
      node {
        thumb: fluid(maxWidth: 270, maxHeight: 270) {
          base64
          aspectRatio
          src
          srcSet
          sizes
        }
        full: fluid(maxWidth: 1024) {
          base64
          aspectRatio
          src
          srcSet
          sizes
        }
      }
    }
  }
}
`;

export default GalleryTemplate;
